import NotFound from '@app/components/atoms/NotFound';
import SimpleContainer from '@app/components/atoms/SimpleContainer';
import TemplateContainer from '@app/components/atoms/TemplateContainer';

const NotFoundContainer = () => {
  return (
    <TemplateContainer>
      <NotFound />
    </TemplateContainer>
  );
};

export default NotFoundContainer;
